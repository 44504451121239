import { useLocation } from "react-router-dom";
import { pages } from "./routes";

const Footer = () => {
  const location = useLocation();
  const path = location.pathname;

  return pages.some((page) => page.path === path) ? (
    <footer className="py-8 mt-auto bg-[#154866]">
      <div className="container mx-auto">
        {/* <div className="flex lg:flex-row flex-col items-center justify-between">

        </div> */}
        <p className="text-slate-300 text-center text-sm">
          All Rights Reserved
        </p>
      </div>
    </footer>
  ) : null;
};

export default Footer;
